import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { DocumentSignerGraphQLService } from '@app/core/document-signer-graphql.service';
import { DocumentSigner } from '@app/core/legal-documents';
import {
  KESTREL_MP_FLOW_VERSION,
  MODULE_LEGAL_DOCUMENT_PAGE,
  SERVICE_AREA_PAGE_SOURCE,
} from '@app/core/mixpanel.constants';
import { CurrentUserIdGraphQLService } from '@app/core/tos/current-user-id-graphql.service';
import { RegistrationAnalyticsService } from '@app/registration';
import {
  GraphNavigationService,
  GraphNavigationAnalyticsProperties,
} from '@app/registration/graph/graph-navigation.service';
import { BeneficiaryForLegalDoc } from '@app/shared';

@Component({
  selector: 'om-tos',
  templateUrl: './tos-interstitial.component.html',
})
export class TosInterstitialComponent implements OnInit {
  documentSignerAndBeneficiary$: Observable<[DocumentSigner, BeneficiaryForLegalDoc]>;

  protected percentComplete: number | null = null;

  constructor(
    private attemptedPathService: AttemptedPathService,
    private documentSignerGraphQLService: DocumentSignerGraphQLService,
    private currentUserIdGraphQLService: CurrentUserIdGraphQLService,
    private graphNavigationService: GraphNavigationService,
    private registrationAnalyticsService: RegistrationAnalyticsService,
  ) {}

  ngOnInit() {
    const documentSigner$ = this.documentSignerGraphQLService
      .fetch()
      .pipe(map(result => result.data.patient.documentSigner));
    const beneficiary$ = this.currentUserIdGraphQLService
      .fetch()
      .pipe(map(result => ({ id: +result.data.patient.id })));

    this.documentSignerAndBeneficiary$ = combineLatest([documentSigner$, beneficiary$]);

    if (this.graphNavigationService.navigationInProgress) {
      this.percentComplete = this.graphNavigationService.percentComplete;
      this.registrationAnalyticsService.graphNavigationPageViewed({
        module: MODULE_LEGAL_DOCUMENT_PAGE,
        source: this.analyticsSource(this.graphNavigationService.analyticsProperties),
        ...this.graphNavigationService.analyticsProperties,
      });
    }
  }

  continue() {
    if (this.graphNavigationService.navigationInProgress) {
      this.graphNavigationService.navigate();
    } else {
      this.attemptedPathService.navigateToAttemptedPath();
    }
  }

  protected analyticsSource(analyticsProperties?: GraphNavigationAnalyticsProperties): string | undefined {
    if (analyticsProperties?.flowVersion === KESTREL_MP_FLOW_VERSION) {
      return SERVICE_AREA_PAGE_SOURCE;
    }
  }
}
