export const FeatureFlags = {
  APPOINTMENT_SEARCH_NEXT_REMOTE_VISIT_MODULE: 'next-remote-visit-module',
  BHX_PROVIDER_BIOS_IN_BOOKING_EXPERIMENT: 'provider-bios-in-booking-experiment',
  BHX_RECOMMENDED_SERVICES_MENTAL_HEALTH_CARD: 'recommended-services-mental-health-card',
  CONSUMER_PEDIATRIC_REGISTRATION_EMAIL_VERIFICATION_STEP: 'consumer-pediatric-registration-email-verification',
  CONSUMER_REGISTRATION_APPLE_PAY_WEB: 'consumer-registration-apple-pay-web',
  CONSUMER_REGISTRATION_DAY_1_BANNER: 'consumer-registration-day-1-banner',
  CONSUMER_REGISTRATION_EXPERIMENTATION_TEST: 'consumer-registration-experimentation-test',
  CONSUMER_REGISTRATION_GIFTING_POLLING_FLOW: 'consumer-registration-gifting-polling-flow',
  CONSUMER_REGISTRATION_INSTALLMENT_PAYMENTS: 'consumer-registration-installment-payments',
  CONSUMER_REGISTRATION_MEMBERSHIP_SERVICE_PUSHER_REFACTOR: 'consumer-registration-membership-service-pusher-refactor',
  CONSUMER_REGISTRATION_PASSWORDLESS_FIRST_PAGE: 'consumer-registration-passwordless-first-page',
  CONSUMER_REGISTRATION_PROMOTION: 'consumer-registration-promotion',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_ALERT_MODAL: 'consumer-registration-service-area-capacity-alert-modal',
  CONSUMER_REGISTRATION_SERVICE_AREA_ADULT_EXCLUSIONS:
    'consumer-registration-exclude-enterprise-only-service-areas-from-adult-flow',
  CONSUMER_REGISTRATION_UPSELL_VIRTUAL_OFFERING: 'consumer-registration-upsell-virtual-offering',
  CONSUMER_REGISTRATION_QUERY_PARAM: 'consumer-registration-query-param',
  CONSUMER_REGISTRATION_SERVICE_AREA_CAPACITY_BLOCK: 'consumer-registration-service-area-capacity-block',
  CONSUMER_REGISTRATION_STEP_BASED_BANNER: 'consumer-registration-step-based-banner',
  CONSUMER_REGISTRATION_STRIPE_PAYMENT_INTENTS: 'consumer-registration-stripe-payment-intents',
  CONSUMER_REGISTRATION_VALUE_PROPS_INTERSTITIAL: 'consumer-registration-value-props-interstitial-step-rev-2',

  ENABLE_UPLOADED_FILE_NAME_SANITIZATION: 'enable-uploaded-file-name-sanitization',
  EXPEDITED_REGISTRATION_FLOW_WITH_SLLTOKEN: 'expedited-registration-flow-with-slltoken',
  EXPEDITED_ENROLLMENT_ACCOUNT_CREATION_COPY_UPDATES: 'expedited-enrollment-account-creation-copy-updates',
  EXPEDITED_ENROLLMENT_ERROR_BANNER_UPDATES: 'expedited-enrollment-error-banner-updates',
  FAMILY_ACCOUNT_MANAGEMENT_TAB: 'family-account-management-tab',
  GRAPHQL_MIGRATION_DELETE_PATIENT_TIMELINE_POST_COMMENT_DRAFT:
    'graph-ql-migration-delete-patient-timeline-post-comment-draft',
  GRAPHQL_MIGRATION_APPOINTMENT_CONFIRMATION: 'graphql-migration-appointment-confirmation',
  HOME_SCREEN_LEGACY_RECOMMENDED_SERVICES: 'home-screen-legacy-recommended-services',
  HINT_PORTAL_PEAK_MED_MEMBERS: 'hint-portal-peak-med-members',
  POST_REGISTRATION_BOOKING: 'post-registration-booking',
  BHX_MEMBER_PROGRAM_EXPERIENCE: 'member-program-experience',
  BHX_MENTAL_HEALTH_TOPIC_ROUTING_FEATURE: 'mental-health-topic-routing-feature',
  PATIENT_UI_MAINTENANCE_MODE: 'patient-ui-maintenance-mode',
  PROACTIVE_CARE_ENABLE_CONCIERGE: 'proactive-care-enable-concierge',
  PROACTIVE_CARE_ENABLE_PCP_SELECTION_ONBOARDING_CAROUSEL_CARD:
    'proactive-care-enable-pcp-selection-onboarding-carousel-card',
  REFERRAL_PDF: 'referral-pdf',
  PC_ONBOARDING_PROMPTS_EXPERIMENT: 'homescreen-onboarding-prompts-web',
  PC_GRAPHQL_NOTIFICATION_TARGETS: 'pc-messages-notification-targets-use-graphql',
  REGISTRATION_FAMILY_ROUTING_FAQS: 'registration-family-routing-faqs',
  PATIENT_TASK_DETAILS_GRAPHQL: 'patient-task-details-graphql',
  TOPIC_BASED_GUIDANCE: 'topic-based-guidance',
  TOPICS_SECTION_HOMESCREEN: 'topics-section-homescreen',
  PC_REGISTRATION_SERVICE_AREA_SUGGESTION: 'pc-registration-service-area-suggestion',
  VEX_DISPLAY_AFTER_HOURS_BILLING_MESSAGE: 'display-after-hours-billing-message',
  REGISTRATION_INSURANCE_LINK: 'registration-insurance-lookup-link',
  REGISTRATION_MFA_CONSUMER: 'mfa-step-consumer-registration',
  REGISTRATION_MFA_PEDIATRIC: 'mfa-step-pediatric-registration',
  REGISTRATION_MFA_DSU: 'mfa-step-dsu',
  REGISTRATION_MFA_PEDIATRIC_DSU: 'mfa-step-pediatric-dsu',
  ACCOUNT_SETTINGS_CONNECTED_DEVICES: 'account-settings-connected-devices',
  PATIENT_DOWNLOAD_EHI_EXPORT: 'patient-download-ehi-export',
  EHI_EXPORT_HISTORY: 'ehi-export-history',
  PC_UPDATED_NOTIFICATION_TARGETS: 'pc-updated-notification-targets',
  IMPROVED_MEDICAL_RECORDS_RELEASE_FORMS: 'improved-medical-records-release-forms',
  NO_COOKIE_FOR_YOU: 'no-cookie-for-you',
  HOMEPAGE_SHOW_INFO_MODAL_FOR_FIRST_REMOTE_VISIT: 'web-show-info-modal-for-first-remote-visit',
  BILLABLE_VISIT_CLARITY_HOMESCREEN_SECTIONS: 'billable-visit-clarity-homescreen-sections',
  HOMESCREEN_TMN_AND_UNC_RENAMING: 'homescreen-tme-and-uvc-renaming',

  // Hornbill
  HORNBILL_ENABLE_FAMILY_TYPE_PAGE_ANALYTICS: 'hornbill-enable-family-type-page-analytics',
  HORNBILL_ENROLLMENT_P0_ENABLED: 'hornbill-enrollment-p0-enabled', // Hornbill Enrollment related P0 features
  HORNBILL_REGISTRATION_BANNER: 'hornbill-registration-reminder-banner',
  HORNBILL_MEMBERSHIP_MANAGEMENT_P0_ENABLED: 'hornbill-membership-mgmt-p0-enabled',
  HORNBILL_DISABLE_REFERRAL_CODE: 'hornbill-disable-referral-code',
  HORNBILL_PRIME_PROMO_BANNER: 'hornbill-prime-promo-banner',
  HORNBILL_ADD_FAMILY_PROMPT_CARD: 'hornbill-add-family-prompt-card',
  HORNBILL_SHOW_UPSELL_NOTICE: 'show-prime-subscription-upsell-notice',
  HORNBILL_SHOW_RENEWAL_OPTIONS: 'direct-renewal-links-to-renewal-options-page',

  /* START PHARMEX */
  /* END PHARMEX */

  // LWA
  LOGIN_WITH_AMAZON: 'login-with-amazon',
  REGISTER_WITH_AMAZON: 'register-with-amazon',

  // GCN
  GCN_ENGAGEMENT_INTERSTITIAL_PAGE_ENABLED: 'gcn-engagement-interstitial-page-enabled',

  // Kestrel
  ENHANCED_FUNCTIONALITY_FOR_LIMITED_ACCESS_USERS: 'enhanced-functionality-for-limited-access-users',
  LIMITED_ACCESS_UPGRADE_MEMBERSHIP: 'limited-access-upgrade-membership',
  LIMITED_ACCESS_ENROLLMENT_SERVICE_AREAS: 'limited-access-enrollment-service-areas',

  // Mobile App Strategy

  /* START insurance capture web */
  INSURANCE_CAPTURE_WEB: 'insurance-capture-web-in-booking',
  INSURANCE_CAPTURE_WEB_TEST_MODE: 'insurance-capture-web',
  REVIEW_APPOINTMENT_BOOKING_FULL_PAGE: 'review-appointment-booking-full-page',
  /* END insurance capture web */
} as const;
export type FeatureFlags = typeof FeatureFlags[keyof typeof FeatureFlags];

export enum HomeRedesignVariation {
  OFF = 'Off',
  ON_ALTERNATE_TEXT = 'OnAlternateText',
}

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround.
 */
export const FeatureFlagVariants = {
  /** Indicates the experiment is not running and should tell the client to not send LD parameters to MP*/
  OFF: 'OFF',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the control group*/
  ON_CONTROL: 'ON CONTROL',
  /** Indicates the experiment is running and should tell the client to send LD parameters to MP for members assigned to the variant group*/
  ON_VARIANT: 'ON VARIANT',
  /** When there are more than 1 variant */
  ON_T1: 'ON T1',
  ON_T2: 'ON T2',
} as const;
export type FeatureFlagVariants = typeof FeatureFlagVariants[keyof typeof FeatureFlagVariants];

/**
 * Variants for LaunchDarkly (LD) x MixPanel (MP) workaround with `ON` variation
 */
export const FeatureFlagVariantsWithOn = {
  ...FeatureFlagVariants,
  /** Indicates the experiment is running and should tell the client to not send LD parameters to MP.*/
  ON: 'ON',
} as const;
export type FeatureFlagVariantsWithOn = typeof FeatureFlagVariantsWithOn[keyof typeof FeatureFlagVariantsWithOn];
